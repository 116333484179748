import {useCallback, useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {AiOutlineEye} from 'react-icons/ai';

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

import Pagination from 'components/Pagination';
import ThreeDotsLoader from 'components/Loaders/ThreeDotsLoader';

import {RideDateFormatter} from 'utils/formatters/formatterResponse';
import {
    requestChangeRideValuesFormater,
    requestInvoiceFormater,
} from 'utils/formatters/formatterRequest';
import {
    requestGetFaturatedRides,
    requestSaveInvoice,
} from 'utils/requests/invoice/invoiceRequests';
import {SpringPage} from 'utils/types/request.types';
import {AllRidesResponse} from 'utils/requests/ride/rideRequests.types';
import {formatExpenseValue} from 'utils/formatters/formatterMoney';

import InvoiceFilter, {InvoiceFilterData} from '../InvoiceFilter';
import ChangeRideValues from 'components/ChangeRideValues';
import './styles.css';

type ControlComponentsData = {
    activePage: number;
    filterData: InvoiceFilterData;
};

const SaveInvoice = () => {
    const MySwal = withReactContent(Swal);
    const history = useHistory();

    const [page, setPage] = useState<SpringPage<AllRidesResponse>>();
    const [selectedRides, setSelectedRides] = useState<AllRidesResponse[]>([]);
    const [saveInvoiceRequest, setSaveInvoiceRequest] = useState(false);
    const [isLoading, setIsloading] = useState<boolean>();
    const [updateList, setUpdateList] = useState<boolean>(false);

    const [controlComponentsData, setControlComponentsData] =
        useState<ControlComponentsData>({
            activePage: 0,
            filterData: {origincity: null, destinycity: null, companyId: null},
        });

    const handlePageChange = (pageNumber: number) => {
        setControlComponentsData({
            activePage: pageNumber,
            filterData: controlComponentsData.filterData,
        });
    };

    const handleSubmitFilter = (data: InvoiceFilterData) => {
        setControlComponentsData({activePage: 0, filterData: data});
    };

    const handleCheck = (checked: boolean, ride: AllRidesResponse) => {
        var updatedList = [...selectedRides];

        if (checked) {
            updatedList = [...selectedRides, ride];
        } else {
            updatedList.splice(selectedRides.indexOf(ride), 1);
        }

        setSelectedRides(updatedList);
    };

    const handleRideClick = (rideId: number) => {
        history.push(`/myapp/menu/ride-history/${rideId}`);
    };

    const saveInvoice = () => {
        if (selectedRides.length) {
            const saveInvoicePayload = requestInvoiceFormater(selectedRides);

            requestSaveInvoice(saveInvoicePayload)
                .then(() => {
                    Swal.fire({title: 'Salvo com sucesso!', icon: 'success'});
                })
                .catch(() => {
                    Swal.fire('Oops', 'Houve um problema', 'error');
                });
        } else {
            Swal.fire({title: 'Selecione pelo menos uma corrida', icon: 'warning'});
        }

        setSaveInvoiceRequest(true);
    };

    const changeRideValues = (isChangeValuePerKm: boolean) => {
        if (selectedRides.length) {
            const formatedPayload = requestChangeRideValuesFormater(selectedRides);

            MySwal.fire({
                title: isChangeValuePerKm ? 'Inserir Km total' : 'Inserir valor total',
                html: (
                    <ChangeRideValues
                        changeRideValuesPayload={formatedPayload}
                        isChangeValuePerKm={isChangeValuePerKm}
                        setUpdateList={setUpdateList}
                        updateList={updateList}
                    />
                ),
                showConfirmButton: false,
            });
        } else {
            Swal.fire({title: 'Selecione pelo menos uma corrida', icon: 'warning'});
        }

        setSaveInvoiceRequest(true);
    };

    const getFaturatedRides = useCallback(() => {
        requestGetFaturatedRides(controlComponentsData)
            .then((response) => {
                setPage(response.data);
            })
            .catch(() => {
                Swal.fire('Oops', 'Houve um problema', 'error');
            })
            .finally(() => {
                setIsloading(false);
            });
    }, [controlComponentsData]);

    useEffect(() => {
        setIsloading(true);
        getFaturatedRides();
    }, [getFaturatedRides, saveInvoiceRequest, updateList]);

    return (
        <div className="overflow">
            {isLoading ? (
                <ThreeDotsLoader/>
            ) : (
                <div>
                    <div className="justify-content-between">
                        <h2 className="page-subtitle">
                            Realizar Fechamento - Corridas pendentes
                        </h2>

                        <InvoiceFilter onSubmitFilter={handleSubmitFilter}/>
                    </div>

                    {page?.content.length ? (
                        <>
                            <div className="overflow">
                                <table className="table-container">
                                    <thead>
                                    <tr>
                                        <th></th>
                                        <th>ID</th>
                                        <th>DATA</th>
                                        <th>FAVORECIDO</th>
                                        <th>EMPRESA</th>
                                        <th>ORIGEM</th>
                                        <th>DESTINO</th>
                                        <th>TOTAL KM</th>
                                        <th>TOTAL R$</th>
                                        <th/>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {page?.content.map((ride) => (
                                        <tr key={ride.ride.id} className="table-animation-effect">
                                            <td>
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    onChange={(e) =>
                                                        handleCheck(e.target.checked, ride)
                                                    }
                                                />
                                            </td>
                                            <td>{`#${ride.ride.id}`}</td>
                                            <td>{RideDateFormatter(ride.ride.scheduleDate)}</td>
                                            <td>
                                                {ride.passenger.firstName +
                                                    ' ' +
                                                    ride.passenger.lastName}
                                            </td>

                                            <td>{ride.passenger.company.name}</td>
                                            <td>{ride.ride.originCity}</td>
                                            <td>{ride.ride.destinyCity}</td>
                                            <td>{ride.ride.km}</td>
                                            <td>{formatExpenseValue(ride.ride.total)}</td>

                                            <td onClick={() => handleRideClick(ride.ride.id!)}>
                                                <AiOutlineEye/>
                                            </td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                            </div>

                            <div className="mt-5">
                                <Pagination
                                    pageCount={page ? page.totalPages : 0}
                                    pageRange={3}
                                    currentPage={page?.number}
                                    onChange={handlePageChange}
                                />
                            </div>

                            <div className="invoice-actions-container mt-5">
                                <button
                                    className="btn btn-quaternary"
                                    onClick={() => saveInvoice()}
                                >
                                    Salvar Fechamento
                                </button>

                                <button
                                    className="btn btn-warning"
                                    onClick={() => changeRideValues(false)}
                                >
                                    Alterar valores das corridas
                                </button>

                                <button
                                    className="btn btn-info"
                                    onClick={() => changeRideValues(true)}
                                >
                                    Alterar Km das corridas
                                </button>
                            </div>
                        </>
                    ) : (
                        <div className="save-invoice-no-content-container">
                            <h2 className="page-subtitle">Não há corridas</h2>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default SaveInvoice;
