import { NavLink } from 'react-router-dom';

//import { BiMap } from 'react-icons/bi';
import { AiOutlineSetting } from 'react-icons/ai';
import { MdHistory } from 'react-icons/md';
import { MdHome } from 'react-icons/md';
import { getAuthData } from 'utils/storage';

const DriverNavBar = () => {
  const hasDriverDocument = getAuthData().hasDriverDocument;

  return (
    <>
      {hasDriverDocument ? (
        <>
          {/*
         Desativado por solicitação do cliente:
         Dia: 04/10
         Motivo: Cliente entende que os motorista não saberão utilizar essa área da melhor forma, porntanto optou por ocultar de forma
         temporária as informações de colocar corrida em andamento ou não.
         <NavLink
            to="/myapp/menu/ride/current-ride"
            className="navigation-item"
            activeClassName="active-navigation-item"
          >
            <BiMap />
            <h3 className="my-app-nav-option">Corrida atual</h3>
          </NavLink>
         */}

          <NavLink
            to="/myapp/menu/ride-history"
            className="navigation-item"
            activeClassName="active-navigation-item"
          >
            <MdHistory />
            <h3 className="my-app-nav-option">Histórico de corridas</h3>
          </NavLink>

          <NavLink
              to="/myapp/menu/driver/dashboard"
              className="navigation-item"
              activeClassName="active-navigation-item"
          >
            <MdHome />
            <h3 className="my-app-nav-option">Meu app</h3>
          </NavLink>

          <NavLink
            to="/myapp/menu/driver/config"
            className="navigation-item"
            activeClassName="active-navigation-item"
          >
            <AiOutlineSetting />
            <h3 className="my-app-nav-option">Configurar conta</h3>
          </NavLink>
        </>
      ) : (
        <NavLink
          to="/myapp/menu/driver/dashboard"
          className="navigation-item"
          activeClassName="active-navigation-item"
        >
          <AiOutlineSetting />
          <h3 className="my-app-nav-option">Completar cadastro</h3>
        </NavLink>
      )}
    </>
  );
};

export default DriverNavBar;
