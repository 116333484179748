import {ReactComponent as DashboardImg} from 'assets/images/welcome_manager_dashboard.svg';

import './styles.css';

const ManagerDashboard = () => {
    return (
        <div className="regular-manager-dashoboard-container">
            <h1>Seja bem-vindo ao Quality App</h1>
            <DashboardImg/>
        </div>
    );
};

export default ManagerDashboard;
