import {BiCar} from 'react-icons/bi';
//import {IoMapOutline} from 'react-icons/io5';
import {MdOutlineManageAccounts, MdHistory} from 'react-icons/md';
import {NavLink} from 'react-router-dom';

const PassengerNavbar = () => {
    return (
        <>
            <NavLink
                to="/myapp/menu/passenger/requestrace"
                className="navigation-item"
                activeClassName="active-navigation-item"
            >
                <BiCar/>
                <h3 className="my-app-nav-option">Solicitar corrida</h3>
            </NavLink>

            <NavLink
                to="/myapp/menu/ride-history"
                className="navigation-item"
                activeClassName="active-navigation-item"
            >
                <MdHistory/>
                <h3 className="my-app-nav-option">Histórico de corridas</h3>
            </NavLink>

            {/*
             Desativado por solicitação do cliente:
             Dia: 04/10
             Motivo: Cliente entende que os motorista não saberão utilizar essa área da melhor forma, porntanto optou por ocultar de forma
             temporária as informações de colocar corrida em andamento ou não.
            <NavLink
                to="/myapp/menu/passenger/ride/current-ride"
                className="navigation-item"
                activeClassName="active-navigation-item"
                >
                <IoMapOutline />
                <h3 className="my-app-nav-option">Corria atual</h3>
                </NavLink>
            */}
            <NavLink
                to="/myapp/menu/passenger/config"
                className="navigation-item"
                activeClassName="active-navigation-item"
            >
                <MdOutlineManageAccounts/>
                <h3 className="my-app-nav-option">Configurar conta</h3>
            </NavLink>
        </>
    );
};

export default PassengerNavbar;
