import axios from 'axios';
import { BASE_URL } from 'utils/requests/requests';
import { getAuthData, getCompanyIdData } from 'utils/storage';
import { RequestCompanyData } from './companyRequests.types';

export const requestCompanyById = (companyId?: string) => {
  const token = getAuthData().access_token;

  const companyById = companyId ?? getCompanyIdData();

  const headers = {
    Authorization: 'Bearer' + token,
  };

  return axios({
    method: 'GET',
    baseURL: BASE_URL,
    url: `/companies/${companyById}`,
    headers,
  });
};

export const requestAllPassengers = () => {
  const token = getAuthData().access_token;

  const headers = {
    Authorization: 'Bearer' + token,
  };

  return axios({
    method: 'GET',
    baseURL: BASE_URL,
    url: `/users`,
    headers,
    params:{
      role:"ROLE_COMPANY, ROLE_PASSENGER",
      size:2000
    }
  });
};

export const requestBackEndAllCompanies = () => {
  const token = getAuthData().access_token;

  const headers = {
    Authorization: 'Bearer' + token,
  };

  return axios({
    method: 'GET',
    baseURL: BASE_URL,
    url: '/companies',
    headers,
  });
};

export const requestBackEndSaveCompany = (
  saveCompanyData: RequestCompanyData
) => {
  const token = getAuthData().access_token;

  const data = JSON.stringify(saveCompanyData);

  const headers = {
    'Content-Type': 'application/json',
    Authorization: 'Bearer' + token,
  };

  return axios({
    method: 'POST',
    baseURL: BASE_URL,
    url: '/companies',
    headers,
    data,
  });
};
