import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { AxiosRequestConfig } from 'axios';
import Swal from 'sweetalert2';

import { requestBackend } from 'utils/requests/requests';

import { Driver, Car } from 'utils/types/entities.types';

import ItemDetails from 'components/ItemDetails';
import ButtonRegular from 'components/Buttons/ButtonRegular';

type UrlParams = {
  driverId: string;
};

type RequestDriverByIdResponse = {
  driver: Driver;
  car: Car;
};

const DriverDetails = () => {
  const [driver, setDriver] = useState<RequestDriverByIdResponse>();
  const [error, setError] = useState<boolean>(false);

  const { driverId } = useParams<UrlParams>();

  useEffect(() => {
    const config: AxiosRequestConfig = {
      method: 'GET',
      url: `users/driver/${driverId}`,
      withCredentials: true,
    };

    requestBackend(config)
      .then((response) => {
        setDriver(response.data);
      })
      .catch(() => {
        setError(true);
      });
  }, [driverId]);

  const deleteDriver = () => {
    Swal.fire({
      icon: 'warning',
      iconColor: '#dc3545',
      title: 'Deseja realmente deletar este motorista?',
      showCancelButton: true,
      confirmButtonText: 'Sim',
      denyButtonText: `Cancelar`,
    }).then((result) => {
      if (result.isConfirmed) {
        const config: AxiosRequestConfig = {
          method: 'DELETE',
          url: `users/${driverId}`,
          withCredentials: true,
        };

        requestBackend(config)
          .then(() => {
            Swal.fire('Motorista deletado', 'Sucesso', 'success');
          })
          .catch(() => {
            Swal.fire('Oops!', 'Erro ao tentar deletar motorista', 'error');
          });
      } else if (result.isDenied) {
        Swal.fire('Deleção não efetuada', '', 'info');
      }
    });
  };

  return (
    <div className="race-details-container p-3 mt-5 base-card">
      <div className="race-details-solicitation-card p-3 container">
        {error ? (
          <div className="alert alert-danger">
            Erro ao tentar trazer informações do motorista
          </div>
        ) : (
          <>
            {driver && (
              <>
                <h1 className="details-card-title">Sobre o motorista</h1>
                <ItemDetails
                  itemData={
                    `${driver.driver.firstName}` +
                    ' ' +
                    `${driver.driver.lastName}`
                  }
                  itemTitle="Nome"
                />

                {driver.driver.phoneNumber && (
                  <ItemDetails
                    itemData={driver.driver.phoneNumber}
                    itemTitle="Telefone"
                  />
                )}

                <ItemDetails itemData={driver.driver.email} itemTitle="Email" />

                <div className="mt-3" />

                <h1 className="details-card-title">Sobre o veículo</h1>
                {driver.car.id ? (
                  <>
                    <ItemDetails
                      itemData={driver.car.vehicle}
                      itemTitle="Modelo"
                    />
                    <ItemDetails
                      itemData={driver.car.plate}
                      itemTitle="Placa"
                    />
                    <ItemDetails itemData={driver.car.color} itemTitle="Cor" />
                    <ItemDetails
                      itemData={driver.car.carYear}
                      itemTitle="Ano"
                    />
                  </>
                ) : (
                  <span>Veículo não cadastrado</span>
                )}
              </>
            )}
          </>
        )}
        <div className="text-end mt-4">
          <ButtonRegular
            text="Deletar"
            className="btn btn-danger me-2"
            onClick={deleteDriver}
          />
        </div>
      </div>
    </div>
  );
};

export default DriverDetails;
